<template>
    <div class="ant-row ant-form-item" style="margin-bottom: 0px">
        <div class="ant-col-4 ant-form-item-label" v-if="label"><label class="">{{label}}</label></div>
        <div v-if="data"><a :href="data.uri" class="download-icon" target="_blank"><i class="fas fa-download"></i></a></div>
        <div class="ant-col-8 ant-form-item-control-wrapper"><div class="ant-form-item-control"><span class="ant-form-item-children">
            <a-upload
                    name="upload"
                    :multiple="bool(multiple)"
                    :action="this.url.API_URL + '/upload/file'"
                    :headers="headers"
                    @change="handleChange">        <a-button> <a-icon type="upload" /> Nahrať súbor</a-button>
            </a-upload></span>
        </div>
        </div>
    </div>
</template>
<script>
    import {getURL} from "../../api";
    import {authHeader} from "../../_helpers";
    export default {
        props: ["multiple","data","dat","label"],
        data() {
            return {
                url: getURL(),
                head: authHeader(),
                headers: authHeader(),
                datatosave: {'uri': this.data,'fid': ''},
            };
        },
        methods: {
            bool(v) {
                return v === "true" || v === "1";
            },
            handleChange(info) {
                if (info.file.status === 'done') {
                    this.$message.success(`${info.file.name} súbor bol nahraný`);

                    this.datatosave = info.file.response[0];
                    this.$emit("changed",this.datatosave);
                } else if (info.file.status === 'error') {
                    this.$message.error(`${info.file.name} file upload failed.`);
                }
            },
        },
        mounted()
        {
            if(this.data)
            {

                this.datatosave['uri'] = this.data.uri;
                this.datatosave['fid'] = this.data.fid;
                this.datatosave['alt'] = this.data.alt;
                this.datatosave['title'] = this.data.title;}

        }
    };
</script>
<style scoped>
    .download-icon
    {float:left;display:block;padding:8px;margin-right:15px;}
</style>
