<template>
     <div>
        <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader class="">
        <CRow>
        <CCol col="6" xl="6">
           <h6 class="w-100">Domov  >  Šablóna</h6>
            <h1 class="w-100" v-if="vars">{{vars.theme.value}}</h1>
        </CCol>
        <CCol col="6" xl="6">
             <div class="text-right system-options w-100 d-block" style="">
              <a-button type="secondary" @click="addByText">Nový komponent</a-button>

            </div>
        </CCol>
        </CRow>
        </CCardHeader>
        <CCardBody>

        <a-modal title="Vytváranie komponentu" centered  v-model="addModalComponent" v-if="addModalComponent" @ok="addComponents" @cancel="fileList = []">
            <div class="clearfix tempplate-uploader">
                <a-upload  name="upload"
                          :multiple = "true"
                          :action="this.url.API_URL + '/upload/file'"
                          :headers="head"
                           @change="handleChange"
                >
                    <a-button> <a-icon type="upload" /> Nahrať súbor </a-button>
                </a-upload>

                <div class="componentSetup" :key="'comp-' + newCompe.filename" v-for="newCompe in fileList">
         <h2>{{newCompe.type}} - {{newCompe.comp}}</h2>
                <h3>{{newCompe.filename}}</h3>
                    <a-form-item
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label="Názov"
                    ><a-input v-model="titles[newCompe.filename]"/></a-form-item>
                    <a-form-item
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label="Popis"
                    ><a-input v-model="descriptions[newCompe.filename]"/></a-form-item>

                    <a-form-item
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label="Kategória">
                        <a-select mode="single" style="width:100%" v-model="categories[newCompe.filename]" @change="(e) => onChildCATChange(e,item,newCompe.filename)" placeholder="Vyberte si z možností">
                            <a-select-option v-for="(op, indd) in taxonomy" :key="op.id">{{op.title}}</a-select-option>
                    </a-select>
                    </a-form-item>
                    <div class="vars">

                        <div class="variables">
                            <h4><div class="row"><div class="col-4"><b style="margin-top:10px">Premenné</b></div><div class="col-6"> <a-input v-model="newfield"></a-input></div><div class="col-2"><a-button class="float-right" @click="addSepareFieldVars">Pridať pole</a-button></div></div></h4>

                        <div class="form-group" :key="'vars-' + variable" v-for="variable,ii in newCompe.vars">
                            <a-form-item
                                    :label-col="formItemLayout.labelCol"
                                    :wrapper-col="formItemLayout.wrapperCol"
                                    :label="variable"
                            >
                                <a-input placeholder="názov poľa" :defaultValue="extradef[newCompe.filename][variable] ? extradef[newCompe.filename][variable]['name'] : ''" @change="(e) => varChange(e,variable,newCompe.filename,'name')"/>
                                <a-select :defaultValue="extradef[newCompe.filename][variable] ? extradef[newCompe.filename][variable]['type'] : 'text'"   style="width:100%" @change="(e) => varChange(e,variable,newCompe.filename,'type')" :options="types"/>

                            </a-form-item>
                            <a-button @click="removeVariable(ii)">X</a-button>
                        </div>
                        </div>


                        <div class="view" >
                            <h4><div class="row"><div class="col-4"><b>Polia views</b></div><div class="col-6"> <a-input v-model="newfield"></a-input></div><div class="col-2"><a-button class="float-right" @click="addSepareFieldViews">Pridať pole</a-button></div></div></h4>
                            <div class="form-group" :key="'view-' + variable" v-for="variable,ii in newCompe.viewFields">
                            <a-form-item
                                    :label-col="formItemLayout.labelCol"
                                    :wrapper-col="formItemLayout.wrapperCol"
                                    :label="variable">
                                <a-input placeholder="názov poľa" :defaultValue="extradef[newCompe.filename][variable] ? extradef[newCompe.filename][variable]['name'] : ''" @change="(e) => varChange(e,variable,newCompe.filename,'name')" />
                            </a-form-item>
                            <a-button @click="removeView(ii)">X</a-button>
                        </div>
                        </div>
                        <div class="setup" v-if="newCompe.twigOptions">
                            <h4>Nastavenia</h4>

                            <div class="form-group" :key="'vieeaef-' + slug" v-for="variable,slug in newCompe.twigOptions">
                                <a-form-item :label-col="formItemLayout.labelCol"
                                             :wrapper-col="formItemLayout.wrapperCol"
                                             :label="slug">
                                    <a-input placeholder="názov výberovníka"  :defaultValue="extradefSet[newCompe.filename][slug] ? extradefSet[newCompe.filename][slug]['name'] : ''" @change="(e) => setupChange(e,slug,newCompe.filename,'name')" />
                                </a-form-item>
                                <a-form-item :key="index" v-for="(option,index) in variable"
                                        :label-col="formItemLayout.labelCol"
                                        :wrapper-col="formItemLayout.wrapperCol"
                                        :label="option">
                                    <a-input placeholder="názov voľby" :defaultValue="extradefSet[newCompe.filename][option] ? extradefSet[newCompe.filename][option]['name'] : ''" @change="(e) => setupChange(e,option,newCompe.filename,'name')" />
                                </a-form-item>
                            </div>
                        </div>
                        <div class="items" >
                          <h4><div class="row"><div class="col-4"><b>Opakované položky</b></div><div class="col-6"> <a-input v-model="newfield"></a-input></div><div class="col-2"><a-button class="float-right" @click="addSepareFieldItems">Pridať pole</a-button></div></div></h4>
                            <div class="form-group" :key="'items-' + variable" v-for="variable,ii in newCompe.itemFields">
                            <a-form-item
                                    :label-col="formItemLayout.labelCol"
                                    :wrapper-col="formItemLayout.wrapperCol"
                                    :label="variable">
                                <a-input placeholder="názov poľa" :defaultValue="extradef[newCompe.filename][variable] ? extradef[newCompe.filename][variable]['name'] : ''" @change="(e) => varChange(e,variable,newCompe.filename,'name')"/>
                                <a-select :defaultValue="extradef[newCompe.filename][variable] ? extradef[newCompe.filename][variable]['type'] : 'text'"   style="width:100%"   @change="(e) => varChange(e,variable,newCompe.filename,'type')" :options="types"/>
                            </a-form-item>
                            <a-button @click="removeItem(ii)">X</a-button>
                        </div>
                        </div>
                    </div>


                </div>
            </div>
        </a-modal>

        <a-modal :title="'Nastavenia ' + titles[editTemplater]" centered  v-model="editTemplater" v-if="editTemplater" @ok="editTemplater = false" @cancel="editTemplater = false">
            <div class="clearfix tempplate-uploader">
                <div class="componentSetup" >
                    <div class="right-part">
                        <div class="ant-row ant-form-item"><div class="ant-col-5 ant-form-item-label"><label title="Ikona" class="">Ikona</label></div>
                            <div class="ant-col-19 ant-form-item-control-wrapper"><div class="ant-form-item-control"
                            ><span class="ant-form-item-children">
                        <upload :data="icons[editTemplater]" :dat="editTemplater" multiple="false" @changed="(e) => onChildChange(e,editTemplater)"></upload>
      </span>
                            </div></div></div>

                    </div>
                    <a-form-item
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label="Názov"
                    ><a-input v-model="titles[editTemplater]"/></a-form-item>
                    <a-form-item
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label="Popis"
                    ><a-input v-model="descriptions[editTemplater]"/></a-form-item>

                    <a-form-item
                            :label-col="formItemLayout.labelCol"
                            :wrapper-col="formItemLayout.wrapperCol"
                            label="Kategória">
                        <a-select mode="single" style="width:100%" v-model="categories[editTemplater]" @change="(e) => onChildCATChange(e,item,editTemplater)" placeholder="Vyberte si z možností">
                            <a-select-option v-for="(op, indd) in taxonomy" :key="op.id">{{op.title}}</a-select-option>
                        </a-select>
                    </a-form-item>
                </div>
            </div>
<span v-if="pages">
            <a-list item-layout="horizontal" :data-source="pages[editTemplater]">
                <a-list-item slot="renderItem" slot-scope="item, index">
                   <a-row type="flex" class="w-100" :gutter="12"><a-col :span="20"> <b>{{item.title}}</b><br><span class="text-muted">{{item.created}}</span></a-col>
                       <a-col :span="4" class="text-right"><router-link :to="'/edit-content/'+item.url" target="_blank" class="ml-2 float-right">
                           <a-button type="secondary" ><i class="fad fa-pencil"></i> Upraviť</a-button>
                       </router-link></a-col></a-row>
                </a-list-item>
            </a-list>
</span>
        </a-modal>


        <a-modal title="Komponent" centered  v-model="editModalComponent" v-if="editModalComponent" @ok="editComponents">
            <div class="clearfix tempplate-uploader">
                <div class="form-group w-100"><label>Názov komponentu</label>
                    <span class="form-control-compact alternative-height" style="border:0;">
                        <a-input v-model="updateName"></a-input>
                    </span>
                </div>
                <div class="form-group w-100"><label>Template</label>
                    <span class="form-control-compact alternative-height" style="border:0;">
              <a-textarea v-model="updateTemp" :rows="12"></a-textarea>
                    </span>
                </div>
                </div>
        </a-modal>

                        <template>
                          <a-card :bordered="false">

                    <div class="container-fluid mt--7" v-if="tempplates">
                <div class="col-xl-12 col-md-9 col-lg-12  order-xl-1">
                    <card shadow type="secondary">
                        <template>
                            <form @submit.prevent="handleSubmit">


    <template v-for="group in taxonomy">
      <h2 class="p-2">{{ group.title }}</h2>

      <template>
   <div class="row">
               <div class="col-md-12">
               <div class="row p-2 mb-2" style="border-bottom:1px solid #efefef;">
                  <div class="col-md-2"><strong>Názov a popis</strong></div>
                  <div class="col-md-2"><strong>Obrázok</strong></div>
                  <div class="col-md-2 text-muted"><strong>Komponent</strong></div>
                  <div class="col-md-1 text-muted"><strong>Na webe</strong></div>
                <div class="col-md-5 text-right"></div>
              </div>
              </div>
              </div>
                  <div class="row">

              <div class="col-md-12" :key="changetemps + dat" v-for="(temp,dat) in tempplates">
                 <template v-if="categorized[group.id]">
<template v-if="categorized[group.id].includes(dat)">
              <div class="row p-2 mb-2" style="border-bottom:1px solid #efefef;">
                  <div class="col-md-2"> {{titles[dat]}} <br><span class="text-muted" style="font-size:12px">{{descriptions[dat]}}</span></div>
                  <div class="col-md-2"><a-popover v-if="icons[dat]" placement="right">
    <div slot="content" v-if="icons[dat]['uri']">
    <img style="width:100%;display:block;" :src="icons[dat]['uri']">
    </div>
   <a-button style="padding:0;" type="link" v-if="icons[dat]['uri']"><img style="max-height:30px;height:auto;max-width:100%" :src="icons[dat]['uri']"> </a-button>
  </a-popover></div>
                  <div class="col-md-2 text-muted">{{dat}}</div>
                  <div class="col-md-1 text-muted">
                      <i class="fa fa-check" v-if="used.includes(dat)"></i>
                      <i class="fa fa-times" v-else></i>
                      </div>
                <div class="col-md-5 text-right">
                    <a-button @click="deletetemp(dat)" type="secondary" size="small"><i class="fa fa-trash pr-1"></i> Zmazať</a-button>
                    <a-button @click="edittempl(dat)" type="secondary" size="small"><i class="fa fa-cog pr-1"></i> Nastavenia</a-button>
                    <a-button @click="edittempplate(dat)" type="secondary" size="small"><i class="fa fa-pencil-alt pr-1"></i> Upraviť šablónu</a-button>
                    <a-button @click="edittfields(dat)" :disabled="!fields[dat]" type="secondary" size="small"><i class="fa fa-pencil-alt pr-1"></i> Upraviť polia</a-button>
                </div>
              </div>
            </template></template>

    </div> </div>


        </template>
    </template>
<template v-if="uncategorized">
      <h2>Nepriradené</h2>
        <template>
          <div class="row">
               <div class="col-md-12">
               <div class="row p-2 mb-2" style="border-bottom:1px solid #efefef;">
                  <div class="col-md-2"><strong>Názov a popis</strong></div>
                  <div class="col-md-2"><strong>Obrázok</strong></div>
                  <div class="col-md-2 text-muted"><strong>Komponent</strong></div>
                  <div class="col-md-1 text-muted"><strong>Na webe</strong></div>
                <div class="col-md-5 text-right"></div>
              </div>
              </div>
              </div>
            <div class="row">


              <div class="col-md-12" :key="changetemps + dat" v-for="(temp,dat) in tempplates">
<template v-if="uncategorized.includes(dat)">
              <div class="row p-2 mb-2" style="border-bottom:1px solid #efefef;">
                  <div class="col-md-2"> {{titles[dat]}} <br><span class="text-muted" style="font-size:12px">{{descriptions[dat]}}</span></div>
                  <div class="col-md-2"><a-popover v-if="icons[dat]" placement="right">
    <div slot="content" v-if="icons[dat]['uri']">
    <img style="width:100%;display:block;" :src="icons[dat]['uri']">
    </div>
   <a-button style="padding:0;" type="link" v-if="icons[dat]['uri']"><img style="max-height:30px;height:auto;max-width:100%" :src="icons[dat]['uri']"> </a-button>
  </a-popover></div>
                  <div class="col-md-2 text-muted">{{dat}}</div>
                  <div class="col-md-1 text-muted">
                      <i class="fa fa-check" v-if="used.includes(dat)"></i>
                      <i class="fa fa-times" v-else></i>
                      </div>
                <div class="col-md-5 text-right">
                    <a-button @click="deletetemp(dat)" type="secondary" size="small"><i class="fa fa-trash pr-1"></i> Zmazať</a-button>
                    <a-button @click="edittempl(dat)" type="secondary" size="small"><i class="fa fa-cog pr-1"></i> Nastavenia</a-button>
                    <a-button @click="edittempplate(dat)" type="secondary" size="small"><i class="fa fa-pencil-alt pr-1"></i> Upraviť šablónu</a-button>
                    <baase-button @click="edittfields(dat)" :disabled="!fields[dat]" type="secondary" size="small"><i class="fa fa-pencil-alt pr-1"></i> Upraviť polia</baase-button>
                </div>
              </div>
            </template>
    </div> </div>


        </template>

      </template>

                <div class="text-right">
                     <button class="btn btn-primary">Uložiť</button>
                </div>

                     </form>   </template>
                    </card>
            </div>
            </div>
               </a-card>
                        </template>


            </CCardBody>
            </CCard>
            </CCol>
            </CRow>

    </div>
</template>

<script>
    import {getURL} from "../api";
    import {authHeader} from "../_helpers";
    import Upload from "./content/Upload";
    import Vue from "vue"
    import FormItem from 'ant-design-vue'
    import {dataService} from "../_services";
    import AUpload from "ant-design-vue/es/upload";
    import AFormItem from "ant-design-vue/es/form/FormItem";
    import ATextarea from "ant-design-vue/es/input/TextArea";
    Vue.use(FormItem);
    const formItemLayout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 19 },
    };
    const formTailLayout = {
        labelCol: { span: 5 },
        wrapperCol: { span: 19, offset: 5 },
    };
    export default {
        name: "tempplate",
        components: {ATextarea, AFormItem, 'upload' : Upload,'a-upload':AUpload},
        props: ["data","values","ct"],
        data() {
            return {
                size: 'small',
                newComponent: null,
                componentId: 0,
                descriptions:{},
                titles:{},
                category:{},
                newField:"",
                categories:{},
                fields:{},
                setup:[],
                fileList: [],
                uploading: false,
                icons:{},
                editModalComponent:false,
                tempplatesSetup:{},
                updateTemp:"",
                sendfieldsData:{},
                addtext:false,
                newComp: {},
                fieldDefinitions:{},
                updateName:"",
                mode: "left",
                tempplates: [],
                taxonomy: {},
                formItemLayout,
                types: [
                    {"value": 'text', "label": 'Text'},
                    {"value": 'selector', "label": 'Výberovník'},
                    {"value": 'image', "label": 'Obrázok'},
                    {"value": 'longtext', "label": 'Dlhý text'},
                    {"value": 'number', "label": 'Celé číslo'},
                    {"value": 'float', "label": 'Desatiné číslo'},
                    {"value": 'switch', "label": 'Logická hodnota Áno/Nie'},
                    {"value": 'icon', "label": 'Ikona'},
                    {"value": 'formpicker', "label": 'Výber formulára'},
                    {"value": 'date', "label": 'Dátum'},
                    {"value": 'daterange u', "label": 'Dátum rozsah'},
                ],
                formTailLayout,
                addModalComponent: false,
                showImages: true,
                vars: {},
                used:[],
                categorized:[],
                uncategorized:[],
                pages:null,
                changetemps:0,
                colorsto: JSON.parse(localStorage.colors),
                tempplatesCat: [],
                imagetoshow: {},
                editfieldees:false,
                extradef:{},
                editfields:false,
                editTemplater:false,
                extradefSet:{},
                edittemp: {},
                datatosave: {},
                url: getURL(),
                head: authHeader(),
                editting: {'id': null,'component':null},
                newcomponents: [],

            };
        },
        methods: {
            handleRemove(file) {
                const index = this.fileList.indexOf(file);
                const newFileList = this.fileList.slice();
                newFileList.splice(index, 1);
                this.fileList = newFileList;
            },
            beforeUpload(file) {
                this.fileList = [...this.fileList, file];

                return false;
            },
            handleChange(e) {
                if(e.file.status === 'done')
                {
                this.addtext = false;
                this.fileList = [...this.fileList, e.file.response.data];
                this.fields[e.file.response.data.filename] = {};
                this.setup[e.file.response.data.filename] = {};
                this.fieldDefinitions[e.file.response.data.filename] = {'type': e.file.response.data.type,'vars': e.file.response.data.vars,'twigOptions': e.file.response.data.twigOptions,'viewFields': e.file.response.data.viewFields, 'itemFields': e.file.response.data.itemFields};
                }
            },
            addSepareFieldViews()
            {
         if(this.newfield)
           {this.fileList[0].viewFields.push(this.newfield);
           this.newfield = '';}
            },
            addSepareFieldItems()
            {
         if(this.newfield)
           {this.fileList[0].itemFields.push(this.newfield);
           this.newfield = '';}
            },
            removeVariable(ii)
            {

 this.fileList[0].vars.splice(ii, 1);

            },
            removeView(ii)
            {

 this.fileList[0].viewFields.splice(ii, 1);

            },
            removeItem(ii)
            {
 this.fileList[0].itemFields.splice(ii, 1);

            },
            addSepareFieldVars()
            {
         if(this.newfield)
           {this.fileList[0].vars.push(this.newfield);
           this.newfield = '';}

            },
            handleUpload() {
                const { fileList } = this;
                const formData = new FormData();
                fileList.forEach(file => {
                    formData.append('files[]', file);
                });
                this.uploading = true;
            },
            edittfields(id)
            {
                this.editModalComponent = false;
                this.fileList = [...this.fileList, this.fieldDefinitions[id] ];

                this.extradef = {}; this.extradefSet = {};

                Object.assign(this.extradef, this.fields);
                Object.assign(this.extradefSet, this.setup);

    this.addModalComponent = true;
            },
            edittempplate(id)
            {
this.addtext = false;
              this.edittemp = id;
              this.updateName = id;
              this.editModalComponent = true;
            },
            edittempl(id)
            {
                this.editTemplater = id;
            },
            deletetemp(id)
            {
                delete this.tempplates[id];
                delete this.titles[id];
                delete this.descriptions[id];
                delete this.icons[id];
                delete this.category[id];
                delete this.categories[id];
                delete this.fields[id];
                delete this.fieldDefinitions[id];
                delete this.setup[id];
                this.changetemps = this.changetemps + 1;
            },
            onChildChange(value,slug) {
                this.icons[slug] = value;
            },
            varChange(value,fieldname,slug,name)
            {
if(name === 'name')
{
    value = value.target.value;
}

                if (typeof( this.fields[slug][fieldname]) === "undefined")
                    this.fields[slug][fieldname]= {'name': '','type': ''};
                this.fields[slug][fieldname][name] = value;

                if (typeof( this.extradef[slug][fieldname]) === "undefined")
                    this.extradef[slug][fieldname]= {'name': '','type': ''};
                this.extradef[slug][fieldname][name] = value;


            },
            setupChange(value,fieldname,slug,name)
            {

                if (typeof( this.setup[slug][fieldname]) === "undefined")
                    this.setup[slug][fieldname]= {'name': '','type': ''};
                this.setup[slug][fieldname][name] = value.target.value;

                if (typeof( this.extradefSet[slug][fieldname]) === "undefined")
                    this.extradefSet[slug][fieldname]= {'name': '','type': ''};
                this.extradefSet[slug][fieldname][name] = value.target.value;
            },
            onChildCATChange(value,slug) {
                this.categories[slug] = value;
            },
            addComponents(e) {
var pa = this;


                Object.assign(this.fields, this.extradef);
                Object.assign(this.setup, this.extradefSet);

                this.fileList.forEach(obj => {
                pa.tempplates[obj.filename] = {};
                })
                this.changetemps = this.changetemps + 1;
    this.addModalComponent = false;
                this.fileList =  [];

            },
            addByText()
            {
                this.editModalComponent = true; this.updateName = ''; this.updateTemp = '';
                this.addtext = true;
            },
            editComponents()
            {

                this.editModalComponent = false;

                dataService.axiosPost("convert", {name: this.updateName, newtext: this.updateTemp}).then(results => {

                    this.fileList = [...this.fileList, results.data.data];

                    this.extradef = {}; this.extradefSet = {};

                    Object.assign(this.extradef, this.fields);
                    Object.assign(this.extradefSet, this.setup);

                  this.fields[results.data.data.filename] = {};
                  this.setup[results.data.data.filename] = {};
if( this.addtext )
{
  results.data.data.vars.forEach(obj => {
  this.setup[results.data.data.filename][obj]= {'name': '','type': ''};
  this.fields[results.data.data.filename][obj]= {'name': '','type': ''};
 });
 results.data.data.itemFields.forEach(obj => {
  this.setup[results.data.data.filename][obj]= {'name': '','type': ''};
   this.fields[results.data.data.filename][obj]= {'name': '','type': ''};
 });
let tempp = {};

 if(results.data.data.twigOptions)
{
for (const [key, value] of Object.entries(results.data.data.twigOptions)) {


   tempp[key]= {'name': '','type': ''};
for (const [keye, valuee] of Object.entries(value)) {
      tempp[valuee]= {'name': '','type': ''};

}
}
}
results.data.data.viewFields.forEach(obj => {
  this.setup[results.data.data.filename][obj]= {'name': '','type': ''};
   this.fields[results.data.data.filename][obj]= {'name': '','type': ''};
 });
   Object.assign(this.extradef, this.fields);
                    Object.assign(this.extradefSet, this.setup);
 }
                 this.fieldDefinitions[results.data.data.filename] = {'type': results.data.data.type,'twigOptions': results.data.data.twigOptions,'vars': results.data.data.vars,'viewFields': results.data.data.viewFields, 'itemFields': results.data.data.itemFields};

                });
                this.addModalComponent = true;

            },
            modalComponent()
            {
                this.addModalComponent = true;
            },
            handleSubmit() {

                this.fieldData = [this.titles,this.descriptions,this.icons,this.categories,this.fields,this.fieldDefinitions,this.setup];

                 dataService.axiosPost("template/" + this.vars.theme.value, this.fieldData).then(results => {

                        if (results.data.success) {
                            this.$message.success('Obsah bol upravený');
                        } else {
                            this.$message.error('Pri ukladaní nastala chyba');
                        }
                    });

            }, },
        mounted() {

            dataService.axiosFetch('taxonomyitems','theme_category').then(results => {
                this.taxonomy = results;
var existingtax = [];
                  this.preselected = this.taxonomy[0].id;

                results.forEach(obj => {
                    existingtax.push(obj.id);

                });
this.existingtax = existingtax;


            dataService.axiosFetch('templatesnew').then(results => {

                this.tempplates = results.components;
                this.tempplatesCat = results.categories;
                this.titles = results.settings.titles;
                this.pages = results.pages;
                this.descriptions = results.settings.descriptions;
                this.categories = results.settings.categories;
                this.descriptions = results.settings.descriptions;
                this.used = results.used;
                this.fieldDefinitions = results.settings.fieldDefinitions;
                this.fields = results.settings.fields;
                this.setup =  results.settings.setupOptions;
                if(this.categories)
                {
for (const [key, value] of Object.entries(this.categories)) {
if(!this.categorized[value])
this.categorized[value] = [];


if(!Array.isArray(value) && this.existingtax.includes(value))
this.categorized[value].push(key);
else
this.uncategorized.push(key);
}
}

                this.icons = results.settings.icons;
            });
            });
            dataService.axiosFetch('variables','web').then(results => {
                this.vars = results;
            });

        }
    }
</script>


<style scoped>
    .element
    {display:block;width:100%; border-top-left-radius: 5px;border-top-right-radius:5px;overflow:hidden;}
.element-image
{width:220px;}
.left-part
{min-height:150px;}
.tempplate-fields
{line-height:15px;}
.tempplate-parts
{
    min-height: 300px;}
    .element.hideimages
    {
        padding:0;
        border-bottom:1px solid #fff;
        border-radius:0;
    }
    .tempplate-parts
    {position:relative;
        margin:10px;
        background-color:#fff;
        padding:20px;
    display:inline-block;width:100%;}
    .manager
    {width:800px}
    .top-holder
    {width:100%;border:1px solid #eceff3; padding:15px   }


    .element span
    {width:100%; display:block;color: #33a6ba;  text-align:left; min-height:27px; padding-left:30px; font-size:14px; line-height:25px;background-color: #a3e4ff;}
    .edit-button
    {float:right; margin:0; margin-left:2px;border-radius:0px; background-color: rgba(0, 0, 0, 0.04); border: 0; color:#fff;
    }
    .ant-radio-button-wrapper:hover
    {opacity:.7}
    .image-holder
    {height:150px;width:250px;float:left;display:block;}
    .builder-content  .ant-radio-button-wrapper img
    {width:100%;}

    .builder-content .ant-radio-button-wrapper::before
    {display:none!important}
    .builder-content  .ant-radio-button-wrapper
    {padding:0;margin-bottom:5px;float:left;margin-right:.5%;
        width:32%;
        overflow:hidden;
        height:100px;
        border:1px solid #d9d9d9;
        line-height:100px;
        display: block;  }
    .handle
    {margin-left:-15px;margin-right:15px;}
    .toggleShower
    {float:right;margin-top:-30px;}
    .edit-button:hover
    {background-color:rgba(0,0,0,.2);}
    .left-part
    { text-align:center;width:100%;display:inline-block;}
    .left-part img
    {margin: 30px auto; display:block;}

    .right-part{
        min-width:420px;
    }
    .tempplate-parts h3
    {text-align:center;}
    .ant-radio-button-wrapper img
    {width:400px}

    .flip-list-move {
        transition: transform 0.5s;
    }
    .no-move {
        transition: transform 0s;
    }
    .ghost {
        opacity: 0.5;
        background: #c8ebfb;
    }
    .list-group {
        min-height: 20px;
    }
    .list-group-item {
        cursor: move;
    }
    .list-group-item i {
        cursor: pointer;
    }
    .vars .form-group
    {padding:10px;margin:0;
  }
    .vars h4
    {font-size:12px;padding-left:20px;margin:0;padding-top:5px;padding-bottom:5px;}
    .vars .variables
    {
        border:1px solid #ffc3a5;
       }
    .vars
    {padding-top:30px;}
    .vars .view
    {
        border:1px solid #7cddff;

    }
    .vars .items
    {
        border:1px solid #b5ffd2;

    }
    .vars .variables .form-group
    {
        background-color: #ffdcc6;
    }
    .vars .view .form-group
    {
        background-color: #b1e7ff;

    }
    .vars .setup
    {
        border:1px solid #b4b3ff;

    }
    .vars .setup .form-group
    {
        background-color: #c2c8ff;

    }
    .vars .items .form-group
    {
        background-color: #afffe2;

    }
    .vars .variables,
    .vars .items,
    .vars .setup,
    .vars .view
    {margin-top:10px;}
    .tempplate-uploader >>> .ant-upload-list
    {display:none}
.componentSetup
{border:1px solid #d2d0d9;padding:10px;padding-top:5px;background-color: #ffffff;margin-bottom:0px;margin-top:20px;}
    .componentSetup h2
    {margin-bottom:0;}
    .componentSetup h3
    {margin-top:5px; font-size:14px;opacity:.4;margin-top:0;margin-bottom:30px;}
</style>
